import { z } from 'zod';
import { useMpMasonryWall } from '@borg/ui';
import {
  AsyncState,
  FormatName,
  JobFilter,
  JobGroup,
  JobSearchParams,
  JobSearchResult,
} from '@borg/types';
import {
  isArray,
  isBoolean,
  isEmptyArray,
  isNumber,
  isNumberArray,
  isNumericStringArray,
  isString,
  isStringArray,
  isUndefined,
  objectKeys,
  toInteger,
} from '@borg/utils';
import { defineInitStore } from './utils';

const queryObjectSchema = z.object({
  locations: z
    .union([z.array(z.string()), z.string()])
    .transform((v) => (isArray(v) ? v : [v]))
    .optional(),
  positions: z
    .union([z.array(z.coerce.number()), z.coerce.number()])
    .transform((v) => (isArray(v) ? v : [v]))
    .optional(),
  newJobs: z
    .null()
    .optional()
    .transform((v) => v === null),
  query: z
    .union([z.array(z.string()), z.string()])
    .transform((v) => (isArray(v) ? v : [v]))
    .optional(),
});

function getInitialStaticFilterState(): JobFilter['static'] {
  return {
    locations: [],
    positions: [],
    newJobs: false,
    query: [],
  };
}

const FEATURED_FORMATS: FormatName[] = [
  'EXCLUSIVE-HOME',
  'EXCLUSIVE',
  'VIDEO',
  'FEATURED-HOME',
  'FEATURED',
];
// todo - review
const HIGHLIGHTED_FORMATS: FormatName[] = ['HIGHLIGHTED', 'Izdvojeni oglas', 'Top oglas'];
const STANDARD_FORMATS: FormatName[] = ['STANDARD'];
const POSLOVAC_FORMATS: FormatName[] = ['Poslovac oglas'];

function jobsSearchStoreFactory(name: 'normal' | 'wide') {
  return defineInitStore(`jobs-search-store-${name}`, () => {
    const router = useRouter();
    const page = ref(0);
    const items = ref<JobGroup[]>([]);
    const totalItems = ref(0);
    const totalPages = ref();
    const searchItem = ref<JobSearchResult['search']>();
    const isSearchSaved = ref<boolean>(false);
    const loadState = ref<AsyncState>('isInitial');
    const filters = reactive<JobFilter>({
      dynamic: {},
      static: getInitialStaticFilterState(),
    });
    const lastQueryString = ref<string>();
    const currentQueryString = ref<string>();
    const strippedFilters = computed(toSearchParams);
    const featuredJobs = computed(() =>
      items.value.filter((g) => FEATURED_FORMATS.includes(g.format)),
    );
    const highlightedJobs = computed(() =>
      items.value.filter((g) => HIGHLIGHTED_FORMATS.includes(g.format)),
    );
    const standardJobs = computed(() =>
      items.value.filter((g) => STANDARD_FORMATS.includes(g.format)),
    );
    const normalJobs = computed(() =>
      items.value.filter(
        (g) =>
          ![
            ...FEATURED_FORMATS,
            ...HIGHLIGHTED_FORMATS,
            ...STANDARD_FORMATS,
            ...POSLOVAC_FORMATS,
          ].includes(g.format),
      ),
    );
    const poslovacJobs = computed(() =>
      items.value.filter((g) => POSLOVAC_FORMATS.includes(g.format)),
    );
    const allResultsListed = computed(
      () =>
        !isUndefined(totalPages.value) &&
        page.value >= totalPages.value &&
        loadState.value === 'isSuccess',
    );
    const isStaticFilterClean = computed(() => {
      return (
        filters.static.locations.length === 0 &&
        filters.static.positions.length === 0 &&
        filters.static.newJobs === false &&
        filters.static.query.length === 0
      );
    });
    const isDynamicFilterClean = computed(() => {
      return objectKeys(filters.dynamic).length === 0;
    });
    const isFilterClean = computed(() => {
      return isStaticFilterClean.value && isDynamicFilterClean.value;
    });
    const hasLocationFilter = computed(() => filters.static.locations.length > 0);

    function toSearchParams(): JobSearchParams & Record<string, unknown> {
      const { locations, positions, newJobs, query } = filters.static;

      const dynamicPart: Record<string, unknown> = {};
      for (const key in filters.dynamic) {
        const value = filters.dynamic[key];
        if (isArray(value)) {
          dynamicPart[key] = value.map((item) => item.id);
        } else if (!isBoolean(value)) {
          dynamicPart[key] = value.id;
        } else {
          dynamicPart[key] = value;
        }
      }

      return {
        ...dynamicPart,
        locations: locations.map((item) => item.name),
        positions: positions.map((item) => item.id),
        newJobs,
        query: [...query],
      };
    }

    async function init(query: typeof router.currentRoute.value.query) {
      // Parse query string
      const queryFilters = parseQuery(query);
      if (queryFilters) {
        currentQueryString.value = objectToQueryString(queryFilters);
        // Resolve filter IDs to "ID - name" pairs so that we can show filter names on UI
        // and make initial search with parsed filter
        await Promise.all([resolveAndSetFilters(queryFilters), search(queryFilters)]);
      }
    }

    async function search(filter: Record<string, unknown> = strippedFilters.value) {
      // Avoid re-fetching on the client if we already have the data matching the current query string
      if (process.client) {
        if (lastQueryString.value === currentQueryString.value) {
          return;
        } else {
          reset();
        }
      }
      lastQueryString.value = currentQueryString.value;

      if (name === 'wide' && isUndefined(filter.locations)) {
        reset('isSuccess', 0);
      }

      await searchNextValues(filter);
    }

    async function searchNextValues(filter: Record<string, unknown> = strippedFilters.value) {
      if (loadState.value === 'inProgress' || page.value >= totalPages.value) {
        return;
      }
      try {
        loadState.value = 'inProgress';
        page.value = page.value + 1;
        const _page = page.value === 1 ? undefined : page.value;
        const excludeLocations = name === 'wide';
        const result = await jobsService.search({ ...filter, page: _page, excludeLocations });
        if (result.isSuccess) {
          items.value = [...items.value, ...result.data.items];
          totalItems.value = result.data.total;
          totalPages.value = result.data.totalPages;
          searchItem.value = result.data.search;
          isSearchSaved.value = result.data.userInfo.isSaved;
          loadState.value = 'isSuccess';
        } else {
          loadState.value = 'isInvalid';
        }
      } catch (error) {
        loadState.value = 'hasError';
      }
    }

    function parseQuery(query: typeof router.currentRoute.value.query) {
      // Parse query string
      const queryParamsParseResult = queryObjectSchema.passthrough().safeParse(query);

      if (queryParamsParseResult.success) {
        // Transform QS parse result to something more manageable = bool or arrays of distinct types (sting|number)
        return objectKeys(queryParamsParseResult.data).reduce<
          Record<string, boolean | string[] | number[]>
        >((acc, key) => {
          const value = queryParamsParseResult.data[key];
          if (key === 'positions' && queryParamsParseResult.data.positions) {
            acc[key] = queryParamsParseResult.data.positions;
          } else if (key === 'locations' && queryParamsParseResult.data.locations) {
            acc[key] = queryParamsParseResult.data.locations;
          } else if (key === 'query' && queryParamsParseResult.data.query) {
            acc[key] = queryParamsParseResult.data.query;
          } else if (key === 'newJobs') {
            acc[key] = queryParamsParseResult.data.newJobs;
          } else if (value === null) {
            acc[key] = true;
          } else if (isString(value)) {
            const numeric = toInteger(value);
            if (numeric) {
              acc[key] = [numeric];
            } else {
              acc[key] = [value];
            }
          } else if (isNumber(value)) {
            acc[key] = [value];
          } else if (isNumericStringArray(value)) {
            acc[key] = value.map((v) => +v);
          } else if (isStringArray(value)) {
            acc[key] = value;
          }
          return acc;
        }, {});
      }
      return {};
    }

    function locallyResolveParams(filter: ReturnType<typeof parseQuery>) {
      return objectKeys(filter).reduce<{
        isSuccess: boolean;
        data: JobFilter;
      }>(
        (acc, key) => {
          if (!acc.isSuccess) {
            return acc;
          }
          const wantedFilterValue = filter[key];

          if (key === 'positions' && isNumberArray(wantedFilterValue)) {
            acc.isSuccess = wantedFilterValue.every((val) =>
              filters.static.positions.map((v) => v.id).includes(val),
            );
            acc.data.static.positions = filters.static.positions.filter((v) =>
              wantedFilterValue.includes(v.id),
            );
          } else if (key === 'locations' && isStringArray(wantedFilterValue)) {
            let matched = wantedFilterValue.every((val) =>
              filters.static.locations.some((loc) => loc.name === val),
            );
            if (!matched) {
              matched = wantedFilterValue.every((val) =>
                filters.static.locations.some(
                  (loc) => loc.name.toLowerCase() === val.toLowerCase(),
                ),
              );
            }

            acc.isSuccess = matched;
            acc.data.static.locations = filters.static.locations.filter((loc) =>
              wantedFilterValue.some(
                (val) => loc.id === val || loc.name?.toLowerCase() === val.toLowerCase(),
              ),
            );
          } else if (key === 'query' && isStringArray(wantedFilterValue)) {
            acc.data.static.query = wantedFilterValue;
          } else if (key === 'newJobs' && isBoolean(wantedFilterValue)) {
            acc.data.static.newJobs = wantedFilterValue;
          } else if (isBoolean(wantedFilterValue)) {
            acc.data.dynamic[key] = wantedFilterValue;
          } else {
            const resolvedDynamicFilterValue = filters.dynamic[key];
            if (isUndefined(resolvedDynamicFilterValue)) {
              acc.isSuccess = false;
            } else if (isBoolean(resolvedDynamicFilterValue)) {
              acc.isSuccess = false;
            } else if (isItem(resolvedDynamicFilterValue)) {
              acc.isSuccess = wantedFilterValue[0] === resolvedDynamicFilterValue.id;
              acc.data.dynamic[key] = resolvedDynamicFilterValue;
            } else if (isArray(wantedFilterValue)) {
              acc.isSuccess = wantedFilterValue.every((v) =>
                resolvedDynamicFilterValue.map((i) => i.id).includes(v),
              );
              acc.data.dynamic[key] = resolvedDynamicFilterValue.filter((v) =>
                wantedFilterValue.includes(v.id),
              );
            }
          }
          // When in doubt, see when this fails...
          // if (!acc.isSuccess) {
          //   console.log(
          //     `Failed to resolve filter '${key}' (maybe this is ok) value`,
          //     wantedFilterValue,
          //     ' from ',
          //     { ...filters },
          //   );
          // }
          return acc;
        },
        {
          isSuccess: true,
          data: { static: getInitialStaticFilterState(), dynamic: {} },
        },
      );
    }

    async function resolveAndSetFilters(filter: ReturnType<typeof parseQuery>) {
      const localFilterResolve = locallyResolveParams(filter);
      const resolvedParams = localFilterResolve.isSuccess
        ? localFilterResolve.data
        : await jobsService
            .resolveParams(filter)
            .then((res) => (res.isSuccess ? res.data : localFilterResolve.data));

      filters.dynamic = resolvedParams.dynamic;
      filters.static = resolvedParams.static;
    }

    function setSearchSaved(value: boolean) {
      isSearchSaved.value = value;
    }

    function cleanDynamicFilter(filter: JobFilter['dynamic']) {
      for (const key in filter) {
        isEmptyArray(filter[key]) || isUndefined(filter[key]) ? delete filter[key] : null;
      }
    }

    function replaceDynamicFilter(filter: JobFilter['dynamic']) {
      cleanDynamicFilter(filter);
      filters.dynamic = filter;
    }

    function setDynamicFilterValues(filter: JobFilter['dynamic']) {
      cleanDynamicFilter(filter);
      filters.dynamic = { ...filters.dynamic, ...filter };
    }

    function setDynamicFilterValue<K extends keyof JobFilter['dynamic']>(
      key: string,
      value?: JobFilter['dynamic'][K],
    ) {
      if (isUndefined(value) || isEmptyArray(value)) {
        delete filters.dynamic[key];
      } else {
        filters.dynamic = { ...filters.dynamic, [key]: value };
      }
    }

    function replaceStaticFilter(filter: JobFilter['static']) {
      filters.static = { ...getInitialStaticFilterState(), ...filter };
    }

    function setStaticFilterValues(filter: Partial<JobFilter['static']>) {
      filters.static = { ...filters.static, ...filter };
    }

    function setStaticFilterValue<K extends keyof JobFilter['static']>(
      key: K,
      value: JobFilter['static'][K],
    ) {
      filters.static = { ...filters.static, [key]: value };
    }

    function cleanFilters() {
      filters.dynamic = {};
      filters.static = getInitialStaticFilterState();
    }

    function reset(
      _loadState: AsyncState = 'isInitial',
      _totalPages: number | undefined = undefined,
    ) {
      if (name === 'normal') {
        useMpMasonryWall('search', 'visible').reset();
        useMpMasonryWall('search', 'hidden').reset();
      }
      loadState.value = _loadState;
      page.value = 0;
      items.value = [];
      totalItems.value = 0;
      totalPages.value = _totalPages;
    }

    return {
      page,
      lastQueryString,
      filters,
      strippedFilters,
      items,
      totalItems,
      totalPages,
      loadState,
      searchItem,
      isStaticFilterClean,
      isDynamicFilterClean,
      isFilterClean,
      isSearchSaved,
      hasLocationFilter,
      init,
      search,
      searchNextValues,
      setSearchSaved,
      featuredJobs,
      highlightedJobs,
      standardJobs,
      poslovacJobs,
      normalJobs,
      allResultsListed,
      replaceDynamicFilter,
      setDynamicFilterValues,
      setDynamicFilterValue,
      replaceStaticFilter,
      setStaticFilterValues,
      setStaticFilterValue,
      cleanFilters,
      reset,
    };
  });
}

export const useJobsSearchStore = jobsSearchStoreFactory('normal');
export const useJobsSearchWideStore = jobsSearchStoreFactory('wide');
